<template>
  <div class="main-table">
    <create v-if="title" :title="title" :form="form" @setting="setting" />
    <div v-else>
      <el-tabs v-model="activeTab" @tab-click="tabClick">
        <el-tab-pane label="大赛中心">
          <div class="courseContainer">
            <span>大赛状态：</span>
            <el-select
              size="mini"
              clearable
              v-model="status"
              @change="setting('搜索')"
              placeholder="下拉选择"
            >
              <el-option
                v-for="status in statusOptions"
                :key="status"
                :value="status"
              >
              </el-option>
            </el-select>
            <el-button
              v-if="isTeacher"
              type="primary"
              size="mini"
              style="margin-left:30px;"
              @click="setting('创建大赛')"
              >创建大赛</el-button
            >
            <el-input
              style="float: right; width: 150px"
              size="mini"
              clearable
              class="right-search"
              placeholder="请输入搜索关键字"
              suffix-icon="el-icon-search"
              @change="setting('搜索')"
              v-model="competitionName"
            >
            </el-input>
          </div>
          <banner-group
            :loading="loading"
            :list="list"
            :activeTab="activeTab"
            :type="isTeacher ? '查看' : '报名'"
            @setting="setting"
          />
        </el-tab-pane>
        <el-tab-pane label="我的大赛">
          <div  class="courseContainer">
        <span>大赛状态：</span>
        <el-select size="mini"  clearable v-model="status" @change="setting('搜索')" placeholder="下拉选择">
          <el-option v-for="status in statusOptions" :key="status" :value="status">
          </el-option>
        </el-select>
        <el-button v-if="isTeacher" type="primary" style="margin-left:30px;" size="mini" @click="setting('创建大赛')">创建大赛</el-button>
        <el-input style="float: right;width: 150px" size="mini" clearable class="right-search" placeholder="请输入搜索关键字" suffix-icon="el-icon-search" @change="setting('搜索')" v-model="competitionName">
        </el-input>
      </div>
          <banner-group
            :loading="loading"
            :list="list"
            :activeTab="activeTab"
            :type="isTeacher ? '设置' : '详情'"
            @setting="setting"
          />
        </el-tab-pane>
      </el-tabs>

      <el-dialog
        title="大赛信息"
        v-model="visible"
        :before-close="handleClose"
        destroy-on-close
        append-to-body
        width="900px"
      >
        <dialog-form
          :isTeacher="isTeacher"
          :isApply="isApply"
          :descriptions="descriptions"
          @handleClose="handleClose"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import create from "../create";
import dialogForm from "../dialog-form";
import bannerGroup from "./components/BannerGroup/index.vue";
import { useRouter } from "vue-router";
import { defineComponent, onMounted, reactive, toRefs, readonly } from "vue";
import {
  competitionList,
  getUserCompetition,
  findCompetitionById,
  update,
  findGroupByCompetitionIdAndUserId,
} from "../apis";
import { userInfo } from "../methods";
import { fatherRoute, mainData } from "../datas";
import { ElMessage } from "element-plus";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "main-table",
  components: {
    create,
    dialogForm,
    bannerGroup,
  },
  props: {
    mineMode: {
      default: "0",
    },
  },
  setup(props) {
    let data = reactive(mainData);
    const router = useRouter();
    const statusOptions = readonly(["未开始", "进行中", "已结束", "已关闭"]);

    onMounted(() => {
      data.title = "";
      // 缓存获取大赛状态
      data.status =
        sessionStorage.getItem("competition-status") == null ||
        !sessionStorage.getItem("competition-status")
          ? ""
          : sessionStorage.getItem("competition-status");
      if (sessionStorage.getItem("competition-tab")) {
        data.activeTab = sessionStorage.getItem("competition-tab");
      } else {
        data.activeTab = props.mineMode;
      }
      data.competitionName = "";
      data.isTeacher = userInfo().roleName === "教师";
      calStatus();
      getList();
    });

    // 给教师、学生设置不同初始值
    const calStatus = () => {
      if (!data.status) {
        data.status = data.isTeacher
          ? "未开始"
          : data.activeTab == "0"
          ? "进行中"
          : "";
      }
    };

    const setting = (type, info) => {
      switch (type) {
        case "详情":
        case "双击banner":
          if (info.status === "进行中") {
            checkMegaCondition(info);
          } else {
            ElMessage.warning("大赛" + info.status);
          }
          break;
        case "设置":
          findMegaById(info.id);
          break;
        case "":
        case "保存":
          data.title = "";
          getList();
          break;
        case "搜索":
          getList();
          break;
        case "创建大赛":
          data.form = {};
          data.title = type;
          break;
        case "查看":
        case "报名":
          data.isApply = info.isSignUp;
          data.visible = true;
          data.descriptions = info;
          break;
        case "打开":
        case "关闭":
          updateMega(info);
          break;
        default:
          break;
      }
      return;
    };

    const checkMegaCondition = (info) => {
      if (info.matchType !== 1 || data.isTeacher) {
        sessionStorage.setItem("mega", JSON.stringify(info));
        sessionStorage.setItem("acid", info.acid);
        router.push({
          path: getRouterLink(),
        });
        return;
      }
      let param = {
        userId: JSON.parse(sessionStorage.getItem("user")).userId,
        competitionId: info.id,
      };
      findGroupByCompetitionIdAndUserId(param).then((res) => {
        if (res.data.code === "200") {
          data.start = res.data.data.doesAGroupAlreadyExist;
          if (data.start) {
            // 是老师的情况 或者是学生的进行中的状态可以进到详情页面
            sessionStorage.setItem("mega", JSON.stringify(info));
            sessionStorage.setItem("acid", info.acid);
            router.push({
              path: getRouterLink(),
            });
          } else {
            router.push({
              path: fatherRoute + "/groupListMega",
              query: info,
            });
          }
        }
      });
    };

    const updateMega = (info) => {
      data.loading = true;
      const reqData = cloneDeep(info);
      reqData.status = "已关闭";
      reqData.userName = userInfo().userName;
      update(reqData)
        .then((res) => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            info.status = "已关闭";
            ElMessage.success(resData.msg);
            getList();
          } else {
            ElMessage.error(resData.msg);
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const tabClick = () => {
      sessionStorage.setItem("competition-tab", data.activeTab);
      calStatus();
      data.competitionName = "";
      getList();
    };

    const getList = () => {
      data.loading = true;

      let reqData = {
        schoolId: userInfo().schoolId,
        status: data.status,
        competitionName: data.competitionName,
      };
      if (data.activeTab == 1) {
        //我的大赛
        data.isTeacher
          ? (reqData.userName = userInfo().userName)
          : (reqData.userId = userInfo().userId);
      }
      if (data.activeTab == 1 && !data.isTeacher) {
        getUserList(reqData);
        return;
      }
      competitionList(reqData)
        .then((res) => {
          //查询成功，将状态缓存
          sessionStorage.setItem("competition-status", reqData.status);
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            data.list = resData.data.competitions || [];
          } else {
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    //学生端 我的大赛接口不一样
    const getUserList = (reqData) => {
      getUserCompetition(reqData)
        .then((res) => {
          //查询成功，将状态缓存
          sessionStorage.setItem("competition-status", reqData.status);
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            data.list = resData.data.competitions || [];
          } else {
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const findMegaById = (id) => {
      data.loading = true;
      const reqData = {
        competitionId: id,
      };
      findCompetitionById(reqData)
        .then((res) => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            data.form = resData.data || {};
            data.title = "设置";
          } else {
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const handleClose = () => {
      data.visible = false;
    };
    //获取跳转路由
    const getRouterLink = () => {
      //获取大赛管理
      let arr = JSON.parse(sessionStorage.getItem("roleList")).filter(
        (item) => {
          if (item.url === "/megagame") {
            return item;
          }
        }
      );
      arr[0].childResources.sort((a, b) => {
        return a.sort - b.sort;
      });
      return arr[0].childResources[0].url;
    };
    return {
      ...toRefs(data),
      statusOptions,
      handleClose,
      setting,
      tabClick,
      checkMegaCondition,
      getRouterLink,
    };
  },
});
</script>

<style scoped lang="less">
.main-table {
  background-color: #fff;
  padding-top:34px;
  height: calc(100vh - 160px);
  overflow-y: auto;
  box-shadow: 0px 2px 20px 0px rgba(107, 133, 228, 0.15);
  border-radius: 6px;
  /deep/ .el-tabs__item.is-active {
    color: #4274fc;
    font-weight: 700;
  }
  /deep/ .el-tabs__nav-scroll{
      padding-left:34px;
  }
  /deep/ .el-tab-pane{
    padding: 0 34px;
    padding-top:10px;
  }
  /deep/.el-tabs__active-bar {
    background-color: #4274fc;
  }
  .courseContainer {
    margin-bottom: 20px;
  }
}
.main-title {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #e4e7ed;
  margin-bottom: 20px;
  display: flex;
}
</style>
